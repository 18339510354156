import { parseTime,mergeRecursive,filterHTML } from '@/utils/common'
import { Partten } from "@/utils/partten";
import cache from './cache'

export default {
	install: (app) => {
		app.config.globalProperties.$cache = cache
		app.config.globalProperties.partten = Partten
		app.config.globalProperties.parseTime = parseTime
		app.config.globalProperties.mergeRecursive = mergeRecursive
		app.config.globalProperties.filterHTML = filterHTML
	}
}