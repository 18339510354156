import cache from '@/plugins/cache'
import { defineStore } from 'pinia'
import { login, register, getinfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'

const useUserStore = defineStore(
  'user',
  {
    state: () => ({
      token: getToken(),
      userid: cache.session.get('krUser-Id') ? parseInt(cache.session.get('krUser-Id')) : '',
      name: cache.session.get('krUser-name') ? cache.session.get('krUser-name') : '',
	  userInfo: cache.session.getJSON('krUser-Info') ? cache.session.getJSON('krUser-Info') : {},
    }),
    actions: {
		// 注册
		register(userInfo) {
		  return new Promise((resolve, reject) => {
		    register(userInfo).then(res => {
		      resolve(res)
		    }).catch(error => {
		      reject(error)
		    })
		  })
		},
      // 登录
      login(userInfo) {
        return new Promise((resolve, reject) => {
          login(userInfo).then(res => {
            setToken(res.token)
            resolve(res)
          }).catch(error => {
            reject(error)
          })
        })
      },
	  setInfo(userInfo){
		  return new Promise((resolve, reject) => {
			setToken(userInfo.token)
		    getinfo().then(res => {
				this.userid = res.data.userId
				this.name = res.data.nickName
				this.userInfo = res.data
		  	  cache.session.setJSON('krUser-Info', res.data);
		  	  cache.session.set('krUser-name', res.data.nickName);
		  	  cache.session.set('krUser-Id', parseInt(res.data.userId));
		  	  resolve(res)
		    }).catch(error => {
		      reject(error)
		    })
		  })
	  },
      // 获取用户信息
      getInfo() {
        return new Promise((resolve, reject) => {
          getinfo().then(res => {
			  this.userid = res.data.userId
			  this.name = res.data.nickName
			  this.userInfo = res.data
			  cache.session.setJSON('krUser-Info', res.data);
			  cache.session.set('krUser-name', res.data.nickName);
			  cache.session.set('krUser-Id', res.data.userId);
			  resolve(res)
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 退出系统
      logOut() {
        return new Promise((resolve, reject) => {
			cache.session.remove('krUser-Info');
			cache.session.remove('krUser-name');
			cache.session.remove('krUser-Id');
			removeToken()
			resolve()
        })
      }
    }
  })
  
export default useUserStore
