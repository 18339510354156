export const moduleCompanyInfo = 1;//公司简介-首页
export const moduleCompanyCultrue = 54;//文化理念
export const moduleCompanyIntroduce = 3;//公司简介-详情
export const moduleExecutive = 2;//高管介绍
export const moduleNews = 4;//大事件
export const moduleGameList = 12;//联研产品
export const moduleGameRecommend = 11;//推荐重点游戏
export const moduleOtherConnecat = 50;//其他配置
export const moduleBannerBig = 14;//首页轮播
export const moduleBannerMix = 19;//小轮播
export const moduleQuesList = 55;//校招问答

export const partRecruitSchool = 7;//校园招聘
export const partRecruitSocial = 6;//社会招聘
