<template>
	<el-config-provider :locale="locale">
		<router-view v-if="isRouterActive"/>
	</el-config-provider>
</template>
<script setup>
import { ref, provide, nextTick } from "vue";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
const locale = ref(zhCn);
const isRouterActive = ref(true)
provide('reload', () => {
	isRouterActive.value = false
	nextTick(() => {
	    isRouterActive.value = true
	})
})
</script>
<style lang="less">
html{
	font-family: Assistant, sans-serif;
	font-size: calc(20 * 1rem / 32);
	color: #121212;
}
</style>
