export const pcRoutes = [
	{
	  path: '/index',
	  component: () => import('@/views/index'),
	  name: 'Index',
	  meta: { title: '首页', activeMenu: '/index' }
	},
	{
	  path: '/pages',
	  component: () => import('@/views/pages/index.vue'),
	  redirect: '/pages/about',
	  name: 'Pages',
	  meta: { title: '公司文化', activeMenu: '/pages', noCache: true },
			children: [
			  {
				path: 'about',
				component: () => import('@/views/pages/about.vue'),
				name: 'About',
				meta: { title: '关于我们', activeMenu: '/pages' },
			  },
			  {
				path: 'contact',
				component: () => import('@/views/pages/contact.vue'),
				name: 'Contact',
				meta: { title: '联系我们', activeMenu: '/pages' },
			  },
			]
	},
	{
	  path: '/products',
	  component: () => import('@/views/products/index.vue'),
	  redirect: '/products/list',
	  name: 'Products',
	  meta: { title: '商品列表', activeMenu: '/products', noCache: true },
			children: [
			  {
				path: 'list',
				component: () => import('@/views/products/list.vue'),
				name: 'pList',
				meta: { title: '商品列表', activeMenu: '/products' },
			  },
			  {
				path: 'detail/:Id(\\d+)',
				component: () => import('@/views/products/detail.vue'),
				name: 'Detail',
				meta: { title: '商品详情', activeMenu: '/products' },
			  },
			]
	},
	{
	  path: '/account',
	  component: () => import('@/views/account/index.vue'),
	  redirect: '/account/login',
	  name: 'Account',
	  meta: { title: '账户', activeMenu: '/account', noCache: true },
			children: [
			  {
				path: 'login',
				component: () => import('@/views/account/login.vue'),
				name: 'Login',
				meta: { title: '登录', activeMenu: '/account' },
			  },
			  {
				path: 'register',
				component: () => import('@/views/account/register.vue'),
				name: 'Register',
				meta: { title: '注册', activeMenu: '/account' },
			  },
			]
	},
	{
	  path: '/cart',
	  component: () => import('@/views/cart/index.vue'),
	  redirect: '/cart/list',
	  name: 'Cart',
	  meta: { title: '购物车', activeMenu: '/cart', noCache: true },
			children: [
			  {
				path: 'list',
				component: () => import('@/views/cart/list.vue'),
				name: 'cList',
				meta: { title: '购物车', activeMenu: '/cart' },
			  },
			]
	},
	{
	  path: '/policies',
	  component: () => import('@/views/policies/index.vue'),
	  redirect: '/policies/privacy',
	  name: 'Policies',
	  meta: { title: '隐私', activeMenu: '/policies', noCache: true },
			children: [
			  {
				path: 'privacy',
				component: () => import('@/views/policies/privacy.vue'),
				name: 'Privacy',
				meta: { title: '政策', activeMenu: '/policies' },
			  },
			  {
				path: 'refund',
				component: () => import('@/views/policies/refund.vue'),
				name: 'Refund',
				meta: { title: '退款', activeMenu: '/policies' },
			  },
			  {
				path: 'user',
				component: () => import('@/views/policies/user.vue'),
				name: 'User',
				meta: { title: '退款', activeMenu: '/policies' },
			  },
			]
	},
	// {
	//   path: '/contact',
	//   component: () => import('@/views/pc/contact'),
	//   name: 'Contact',
	//   meta: { title: '联系我们', activeMenu: '/contact' }
	// },
];