import request from "@/utils/request";
import { encryptByDES } from '@/utils/3DES.js'
// 登录
export function login(data) {
  return request({
    url: '/user/login/on',
    headers: {
      isToken: false
    },
    method: 'post',
	data: data
  })
}

// 注册
export function register(data) {
  return request({
    url: '/user/login/add',
    headers: {
      isToken: false
    },
    method: 'post',
	data: data
  })
}

// 用户信息
export function getinfo() {
  return request({
    url: '/function/shoppingCart/getUserInfo',
    headers: {
      isToken: true
    },
    method: 'post'
  })
}

// 谷歌登录
export function googleLogin(data) {
  return request({
    url: '/google/verify',
    headers: {
      isToken: false
    },
    transformRequest:[function(data){
    	return data
    }],
    method: 'post',
    data: encryptByDES(JSON.stringify(data))
  })
}
// 验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get'
  })
}